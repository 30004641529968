import { Suspense, lazy, useState, useCallback } from "react";
import { Frame, Loading } from "@dentsu-ui/components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { SideNavigation } from "./components";
import HierarchyContext from "./context/HierarchyContext";
import { useApolloClient } from "@apollo/client";
import { getServiceConfig } from "@gdo-global-client-reporting/dentsu-platform-bridge";
import { BPL_GET_CLIENT_DATA } from "./graphql/queries";
import PageNotFound from "./components/Fallbacks/PageNotFound";
import Forbidden from "./components/Fallbacks/Forbidden";
import { getClientLogoImage } from "./utils/common";
import { CreateProjectProvider } from "./context/CreateProjectProvider";
import { CreateScenarioProvider } from "./context/CreateScenarioProvider";
import { CompareScenariosProvider } from "./context/CompareScenariosProvider";
import { ProjectProvider } from "./context/ProjectProvider";
const Projects = lazy(() => import("./modules/Projects"));
const Scenarios = lazy(() => import("./modules/Scenarios"));
const Comparision = lazy(() => import("./modules/Comparision"));

function Routes(props) {
  const client = useApolloClient();

  const [clientData, setClientData] = useState({
    clientLogo: "default_logo.png",
    hierarchyData: {},
  });

  const { clientCode } = getServiceConfig();

  const fetchClientData = useCallback(
    () =>
      client
        .query({
          query: BPL_GET_CLIENT_DATA,
          variables: { clientCode },
        })
        .then(async ({ data }) => {
          setClientData({
            hierarchyData: data,
            clientLogo:
              data.aueGetClientLogo.logoImageUrl !== "showDefaultLogo"
                ? await getClientLogoImage(data.aueGetClientLogo.logoImageUrl)
                : "default_logo.png",
          });
        })
        .catch((err) => {
          console.log("Error in fetching client Dat: ", err);
        }),
    [client, clientCode]
  );
  console.log("fetchClientData", fetchClientData);

  return (
    <HierarchyContext.Provider
      value={{
        hierarchyData: clientData.hierarchyData,
        clientLogo: clientData.clientLogo,
      }}
    >
      <CreateProjectProvider>
        <CreateScenarioProvider>
          <Router>
            <Frame navigation={<SideNavigation />}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/projects" />
                </Route>
                <Route exact path="/projects">
                  <Suspense fallback={<Loading />}>
                    <Projects />
                  </Suspense>
                </Route>
                <Route exact path="/project/:projectId/scenarios">
                  <Suspense fallback={<Loading />}>
                    <ProjectProvider>
                      <Scenarios />
                    </ProjectProvider>
                  </Suspense>
                </Route>
                <Route exact path="/project/:projectId/comparision">
                  <Suspense fallback={<Loading />}>
                    <ProjectProvider>
                      <CompareScenariosProvider>
                        <Comparision />
                      </CompareScenariosProvider>
                    </ProjectProvider>
                  </Suspense>
                </Route>
                <Route exact path="/*">
                  <PageNotFound />
                </Route>
                <Route exact path="/403">
                  <Forbidden />
                </Route>
              </Switch>
            </Frame>
          </Router>
        </CreateScenarioProvider>
      </CreateProjectProvider>
    </HierarchyContext.Provider>
  );
}

export default Routes;
