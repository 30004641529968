import { ScenarioStatus, ScenarioTypes } from "../../Constant";

export const scenarioData = [
  {
    id: "Scenario001",
    originalId: "Scenario001",
    type: ScenarioTypes.EXISTING_PLAN,
    businessId: "SCN0001",
    description: "Benchmark (current plan)",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Base",
    budget: 227956,
    primaryKpi: { name: "Incremental Car Config Finished", target: 0, current: 332205 },
    secondaryKpi: { name: "CPC(% Change)", target: 0.00, current: 0.00 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project001",
  },
  {
    id: "Scenario002",
    originalId: "Scenario002",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0002",
    description: "Same Budget & Optimized Mix",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Scenario 2",
    budget: 227956,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 332205,
      current: 339460,
    },
    secondaryKpi: { name: "CPC(% Change)", target: -0.0214, current: -0.0214 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project001",
  },
  {
    id: "Scenario003",
    originalId: "Scenario003",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0003",
    description: "+20% Budget & Optimized Mix",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Scenario 3",
    budget: 273547,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 332205,
      current: 343798,
    },
    secondaryKpi: { name: "CPC(% Change)", target: 0.1595, current: 0.1595 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project001",
  },
  {
    id: "Scenario004",
    originalId: "Scenario004",
    type: ScenarioTypes.FIND_THE_BUDGET,
    businessId: "SCN0004",
    description: "Same Car Config Finished & Optimized Mix",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Scenario 4",
    budget: 181953,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 332205,
      current: 332205,
    },
    secondaryKpi: { name: "CPC(% Change)", target: -0.2018, current: -0.2018 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project001",
  },
  {
    id: "Scenario005",
    originalId: "Scenario005",
    type: ScenarioTypes.EXISTING_PLAN,
    businessId: "SCN0005",
    description: "Benchmark (current plan)",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Base",
    budget: 352677,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 0,
      current: 120214,
    },
    secondaryKpi: { name: "CPC(% Change)", target: 0.00, current: 0.00 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project002",
  },
  {
    id: "Scenario006",
    originalId: "Scenario006",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0006",
    description: "Same Budget & Optimized Mix",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Scenario 2",
    budget: 352677,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 120214,
      current: 123301,
    },
    secondaryKpi: { name: "CPC(% Change)", target: -0.0250, current: -0.0250 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project002",
  },
  {
    id: "Scenario007",
    originalId: "Scenario007",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0007",
    description: "+20% Budget & Optimized Mix",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Scenario 3",
    budget: 423213,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 120214,
      current: 124738,
    },
    secondaryKpi: { name: "CPC(% Change)", target: 0.1565, current: 0.1565 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project002",
  },
  {
    id: "Scenario008",
    originalId: "Scenario008",
    type: ScenarioTypes.FIND_THE_BUDGET,
    businessId: "SCN0008",
    description: "Same Car Config Finished & Optimized Mix",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Scenario 4",
    budget: 282507,
    primaryKpi: {
      name: "Incremental Car Config Finished",
      target: 120214,
      current: 120214,
    },
    secondaryKpi: { name: "CPC(% Change)", target: -0.1990, current: -0.1990 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "Project002",
  },
];

export const scenarioData1 = [
  {
    id: "1",
    originalId: "1",
    type: ScenarioTypes.FIND_THE_BUDGET,
    businessId: "SCN0001",
    description:
      "You can change the name and this description in the scenario settings on the view scenario screen",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Half the budget expected",
    budget: 1300000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 9000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.495, current: 0.51 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "1",
  },
  {
    id: "2",
    originalId: "2",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0002",
    description:
      "You can change the name and this description in the scenario settings on the view scenario screen",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Full budget available",
    budget: 1500000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 8000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.495, current: 0.51 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "1",
  },
  {
    id: "3",
    originalId: "3",
    type: ScenarioTypes.FIND_THE_BUDGET,
    businessId: "SCN0003",
    description:
      "You can change the name and this description in the scenario settings on the view scenario screen",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Find the budget scenario",
    budget: 1600000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 2000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.495, current: 0.51 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "2",
  },
  {
    id: "4",
    originalId: "4",
    type: ScenarioTypes.FIND_THE_BUDGET,
    businessId: "SCN0004",
    description:
      "You can change the name and this description in the scenario settings on the view scenario screen",
    status: ScenarioStatus.SENT_TO_CLIENT,
    name: "Find the budget scenario",
    budget: 1900000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 3000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.495, current: 0.51 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "2",
  },
  {
    id: "5",
    originalId: "5",
    type: ScenarioTypes.FIND_THE_BUDGET,
    businessId: "SCN0005",
    description: "Only have half the budget available.",
    status: "Checking",
    name: "Half the budget expected",
    budget: 2200000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 4000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.55, current: 0.41 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "3",
  },
  {
    id: "6",
    originalId: "6",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0006",
    description: "As much budget as we can find and using all our resources.",
    status: "Checking",
    name: "Full budget available",
    budget: 22300000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 5000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.61, current: 0.44 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "3",
  },
  {
    id: "7",
    originalId: "7",
    type: ScenarioTypes.EXISTING_PLAN,
    businessId: "SCN0007",
    description: "Less budget being used, focus is on audience targeting.",
    status: "Draft",
    name: "Focus on targeting",
    budget: 3300000,
    primaryKpi: { name: "Media profit", target: 4000000, current: 7000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.51, current: 0.51 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "3",
  },
  {
    id: "8",
    originalId: "8",
    type: ScenarioTypes.GIVEN_BUDGET,
    businessId: "SCN0008",
    description:
      "You can change the name and this description in the scenario settings on the view scenario screen",
    status: "Draft",
    name: "Full budget available",
    budget: 4300000,
    primaryKpi: { name: "Media profit", target: 6000000, current: 7000000 },
    secondaryKpi: { name: "Brand Recall", target: 0.495, current: 0.51 },
    diagnosticKpi: ["Media profit", "Revenue"],
    project: "3",
  },
];
