import React from "react";
import ReactDOM from "react-dom";
import "@dentsu-ui/components/styles.css";
import ShellIntegration from "./ShellIntegration";
import "react-app-polyfill/ie11";
import config from "./config";
import { invokeSre } from "./SreIntegration";

// import * as serviceWorker from './service-worker';

// re-build - 01

invokeSre(config.instrumentationKey);

ReactDOM.render(<ShellIntegration />, document.getElementById("dc-app"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
