import { getKeyByValue } from "./utils/common";

export const constant = {
  applicationId: "", // enter the application id used in user management
  fallBackMessage:
    "This User does not have permission to access this application",
  moduleName: "UserList",
  collectionName: "business-planner", // enter the name of the collection used in strapi
  appTitle: "BPL",
};

export const ProjectTypeOptions = Object.freeze({
  BRAND_AND_MARKET_PLANNING: "Brand and market planning",
});

export const ScenarioStatus = Object.freeze({
  SENT_TO_CLIENT: "Sent to client",
  DRAFT: "Draft",
  APPROVED: "Approved",
});

export const ScenarioTypes = Object.freeze({
  FIND_THE_BUDGET: "Find the budget",
  GIVEN_BUDGET: "Given Budget",
  EXISTING_PLAN: "Existing plan",
});

export const newProjectDefaults = {
  name: "",
  startDate: null,
  endDate: null,
  currency: "EUR",
  brief: undefined,
  markets: [],
  brands: [],
  channels: [],
};

export const PROJECT_NAME_MAX_LENGTH = 100;

export const KPIFieldValueType = Object.freeze({
  DECIMAL: "decimal",
  PERCENT: "percent",
  CURRENCY: "currency",
});

export const kpiFieldTypes = Object.freeze({
  "Media profit": getKeyByValue(KPIFieldValueType, KPIFieldValueType.CURRENCY),
  Revenue: getKeyByValue(KPIFieldValueType, KPIFieldValueType.CURRENCY),
  Leads: getKeyByValue(KPIFieldValueType, KPIFieldValueType.DECIMAL),
  Units: getKeyByValue(KPIFieldValueType, KPIFieldValueType.DECIMAL),
  "Foot traffic": getKeyByValue(KPIFieldValueType, KPIFieldValueType.DECIMAL),
  "Brand Recall": getKeyByValue(KPIFieldValueType, KPIFieldValueType.PERCENT),
  "Spontaneous awareness": getKeyByValue(
    KPIFieldValueType,
    KPIFieldValueType.PERCENT
  ),
  "Prompted awareness": getKeyByValue(
    KPIFieldValueType,
    KPIFieldValueType.PERCENT
  ),
  "Comms awareness": getKeyByValue(
    KPIFieldValueType,
    KPIFieldValueType.PERCENT
  ),
  Preference: getKeyByValue(KPIFieldValueType, KPIFieldValueType.PERCENT),
  "Carbon emissions": getKeyByValue(
    KPIFieldValueType,
    KPIFieldValueType.PERCENT
  ),
  Attention: getKeyByValue(KPIFieldValueType, KPIFieldValueType.PERCENT),
  Viewability: getKeyByValue(KPIFieldValueType, KPIFieldValueType.PERCENT),
  "Incremental Car Config Finished": getKeyByValue(
    KPIFieldValueType,
    KPIFieldValueType.DECIMAL
  ),
  incrementalCarConfigFinished: getKeyByValue(
    KPIFieldValueType,
    KPIFieldValueType.DECIMAL
  ),
});

export const ScenarioIdsUrlSeparator = ",";

export const MinScenariosToCompare = 2;
export const MaxScenariosToCompare = 4;
export const CompareScenarioView = Object.freeze({
  DETAILED_VIEW: 1,
  COMPACT_VIEW: 2,
});

export const BryntumControlFactors = Object.freeze({
  MARKET: "market",
  BRAND: "brand",
  CHANNEL: "channel",
});

export const BryntumDurations = Object.freeze({
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
});

export const compareScenariosColors = Object.freeze([
  "#6AA6EB",
  "#8C5ED5",
  "#A32DC1",
  "#00A1A5",
]);
