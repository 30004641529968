import { Toast } from "@dentsu-ui/components";

const toastWrapper = ({type, title, message, duration = 5000}) => {
  const toast = Toast();

  toast({
    title,
    content: message,
    status: type,
    duration,
    isClosable: false,
    position: "top-right",
  });
};

export const ToastType = {
  success: "success",
  danger: "danger",
  warning: "warning",
  info: "info",
  task: "task",
  notification: "notification",
};

export default toastWrapper;
