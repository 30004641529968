import gql from 'graphql-tag';

export const BPL_GET_CLIENT_DATA = gql`
  query bplGetClientData($clientCode: String!) {
    bplGetClientStructure(clientCode: $clientCode) {
      data
      message
      status
    }
    bplGetClientLogo(clientCode: $clientCode) {
      clientCode
      logoImageUrl
    }
  }
`;
