import { EmptyState, Paragraph, Link } from "@dentsu-ui/components";
import { forbiddenConstants } from "../../cms/constants";

const Forbidden = () => {
  return (
    <>
      <EmptyState
        layout="horizontal"
        image="time"
        metadata={
          forbiddenConstants.BPL_fallbacks_forbidden_emptystate_metadata_error403
        }
        title={forbiddenConstants.BPL_fallbacks_forbidden_emptystate_title}
        footer={
          <>
            If you're still unable to access, please raise a ticket in{" "}
            <Link>GoTo</Link> for getting access.
          </>
        }
      >
        <Paragraph>
          {forbiddenConstants.BPL_fallbacks_forbidden_emptystate_paragraph_data}
        </Paragraph>
      </EmptyState>
    </>
  );
};

export default Forbidden;
