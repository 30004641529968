import PlatformShell from "@gdo-global-client-reporting/dentsu-platform-shell";
import { constant } from "./Constant";
import config from "./config";
import BridgeIntegration from "./BridgeIntegration";

const ShellIntegration = () => {
  const cmsConfig = { collectionName: constant.collectionName };

  const shellProps = {
    cmsConfig,
    showSideBar: false,
    isEmbeddedApp: config.isEmbeddedApp,
    appTitle: config.appTitle,
  };
  return (
    <PlatformShell {...shellProps}>
      <BridgeIntegration />
    </PlatformShell>
  );
};

export default ShellIntegration;
