import { useHistory } from "react-router-dom";
import { EmptyState, Paragraph } from "@dentsu-ui/components";
import { fallbackConstants } from "../../cms/constants";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <>
      <EmptyState
        layout="horizontal"
        size="large"
        image="time"
        metadata={fallbackConstants.BPL_fallback_emptystate_metadata_error404}
        title={fallbackConstants.BPL_fallback_emptystate_title_pagenotfound}
        actions={{
          secondary: {
            label: `${fallbackConstants.BPL_fallback_emptystate_secondary_action_gobackhome}`,
            onClick: () => history.push("/"),
            isDisabled: false,
            isExternal: true,
            iconLeft: "home",
            iconRight: null,
            isLoading: false,
          },
        }}
      >
        <Paragraph>
          {fallbackConstants.BPL_fallback_emptystate_error_pagenotfound}
        </Paragraph>
      </EmptyState>
    </>
  );
};

export default PageNotFound;
