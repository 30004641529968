export const invokeSre = async (instrumentationKey) => {
  if (instrumentationKey) {
    const { ApplicationInsights } = await import(
      "@microsoft/applicationinsights-web"
    );
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView({ name: "DAN | OIP" });
  }
};
