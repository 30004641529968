const envVars = process.env;
const config = {
  env: envVars.REACT_APP_NODE_ENV,
  uri: `${envVars.REACT_APP_APOLLO_GRAPHQL_URI}/graphql`,
  sUri: `${envVars.REACT_APP_APOLLO_SUBSCRIPTION_URI}/graphql`,
  okta: {
    issuer: envVars.REACT_APP_OKTA_ISSUER,
    redirect_uri: `${window.location.origin}/implicit/callback`,
    client_id: envVars.REACT_APP_CLIENT_ID,
  },
  appOrigin: envVars.REACT_APP_BASE_URL,
  isBypassSecurity: envVars.REACT_APP_BYPASS_SECURITY,
  cmsURL: envVars.REACT_APP_CMS_URL,
  appTitle: envVars.REACT_APP_NAME,
  //TODO: change isEmbeddedApp config code once .env is updated on remote envs
  isEmbeddedApp:
    envVars.REACT_APP_NODE_ENV === "development"
      ? envVars.REACT_APP_IS_EMBEDDED_APP === "true"
      : true,
  instrumentationKey: envVars.REACT_APP_INSTRUMENTATION_KEY,
  isEnable: true,
  version: "Alpha",
  location: "IN",
  serviceConfig: {
    tenantId: "h06pn82mt",
    clientCode: "A_CCCO",
    clientName: "The Coca Cola Company",
    appCode: "AUE",
  },
  // gtmId: envVars.REACT_APP_GTM_ID,
  // gtmAuth: envVars.REACT_APP_GTM_AUTH,
  // gtmPreview: envVars.REACT_APP_GTM_PREVIEW,
};

export default config;
