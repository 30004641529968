export const groupedOptionsData1 = [
  {
    label: "Car Config Finished",
    options: [
      { value: "IncrementalCarConfigFinished", label: "Incremental Car Config Finished" },
      { value: "TotalCarConfigFinished", label: "Total Car Config Finished" },
      { value: "ROI", label: "CPC(% Change)" },
    ],
  },
  // {
  //   label: "Sales",
  //   options: [
  //     { value: "currency", label: "Media profit"},
  //     { value: "currency", label: "Revenue" },
  //     { value: "text", label: "Leads" },
  //     { value: "units", label: "Units" },
  //     { value: "text", label: "Foot traffic" },
  //   ],
  // },
  // {
  //   label: "Attitudinal",
  //   options: [
  //     { value: "percentage", label: "Brand Recall" },
  //     { value: "Spontaneous awareness", label: "Spontaneous awareness" },
  //     { value: "Prompted awareness", label: "Prompted awareness" },
  //     { value: "Comms awareness", label: "Comms awareness" },
  //     { value: "percentage", label: "Preference" },
  //   ],
  // },
  // {
  //   label: "Other",
  //   options: [
  //     { value: "Carbon emissions", label: "Carbon emissions" },
  //     { value: "Attention", label: "Attention" },
  //     { value: "Viewability", label: "Viewability" },
  //   ],
  // },
];

export const groupedOptionsData = [
  {
    label: "Car Config Finished",
    options: [
      { value: "IncrementalCarConfigFinished", label: "Incremental Car Config Finished", format:"decimal" },
      { value: "TotalCarConfigFinished", label: "Total Car Config Finished", format:"decimal" },
      { value: "ROI", label: "CPC(% Change)", format:"percent" },
    ],
  },
  // {
  //   label: "Attitudinal",
  //   options: [
  //     { value: "percentage", label: "Brand recall", format:"percentage"},
  //     { value: "percentage", label: "Spontaneous brand awareness", format:"percentage"},
  //     { value: "percentage", label: "Prompted brand awareness", format:"percentage"},
  //     { value: "percentage", label: "Brand strength / Preference", format:"percentage"},
  //   ],
  // },
  // {
  //   label: "Other",
  //   options: [
  //     { value: "Carbon emissions", label: "Carbon emissions", format:"other"},
  //     { value: "Attention", label: "Attention", format:"decimal"},
  //     { value: "ROI", label: "ROI", format:"decimal"},
  //   ],
  // },
];

