import { ProjectTypeOptions } from "../../../../Constant";
export const projectData = [
  {
    id: "Project001",
    originalId: 1,
    type: ProjectTypeOptions.BRAND_AND_MARKET_PLANNING,
    name: "VWG Market A",
    brief: "Brief001",
    startDate: "01/01/2023",
    endDate: "12/31/2023",
    haloEffect: false,
    markets: ["Market A"],
    marketsTemp: ["Market000"],
    brands: ["Model1", "Model20"],
    brandsTemp: ["Brand001"],
    channels: [
      "Programmatic Model 20 Campaign 73",
      "Programmatic Model 20 Campaign 74",
      "Programmatic Model 20 Campaign 77",
      "Programmatic Model 1 Campaign 78",
      "Social Model 20 Campaign 92",
      "Social Model 20 Campaign 93",
      "Social Model 1 Campaign 94",
    ],
    channelsTemp: [
      "Channel003",
      "Channel004",
      "Channel005",
      "Channel006",
      "Channel008",
      "Channel009",
      "Channel0010"
    ],
    currency: "EUR",
    businessId: "BP-PRJ0001",
  },
  {
    id: "Project002",
    originalId: 2,
    type: ProjectTypeOptions.BRAND_AND_MARKET_PLANNING,
    name: "VWG Market B",
    brief: "Brief002",
    startDate: "01/01/2023",
    endDate: "12/31/2023",
    haloEffect: false,
    markets: ["Market B"],
    marketsTemp: ["Market001"],
    brands: ["Model1-27"],
    brandsTemp: ["Brand002"],
    channels: [
      "Search Model 1 Campaign All",
      "Search Model 2-27 Campaign All",
      "Social Model 1 Campaign All",
      "Social Model 11 & 18 Campaign All"
    ],
    channelsTemp: [
      "Channel013",
      "Channel014",
      "Channel016",
      "Channel017"
    ],
    currency: "EUR",
    businessId: "BP-PRJ0002",
  },
];
