import { groupedOptionsData } from "../modules/Scenarios/components/ScenarioWizard/components/KPIStep/components/KPIModal/mock";

export const getClientLogoImage = async (imageUrl) => {
  const storage = localStorage.getItem("okta-token-storage");
  const token = storage && JSON.parse(storage)?.accessToken?.accessToken;
  const logoUrlResponse = await fetch(imageUrl, {
    method: "GET",
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const imageBlob = await logoUrlResponse.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  return imageObjectURL;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getCurrencySymbol = (kpi) => {
  const option = groupedOptionsData.find((group) =>
    group.options.find((option) => option.value === kpi.value)
  );
  if (option) {
    const selectedOption = option.options.find(
      (option) => option.value === kpi.value
    );
    switch (selectedOption.value) {
      case "percentage":
        return "%";
      case "text":
        return "";
      case "currency":
        return "£";
      default:
        return "";
    }
  }
  return "";
};
