import gql from 'graphql-tag';

export const GET_USERS = gql`
  query {
    getOipUsers {
      id
      name
      email
      department
      country
      currency
    }
  }
`;
