import React, { useState } from "react";

export const ProjectContext = React.createContext(null);

export function ProjectProvider({ children }) {
  const [project, setProject] = useState({});
  return (
    <ProjectContext.Provider
      value={{
        project,
        setProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
