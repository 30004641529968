import React, { useState } from "react";

export const CompareScenariosContext = React.createContext(null);

export function CompareScenariosProvider({ children }) {
  const [scenariosToCompare, setScenariosToCompare] = useState([]);
  return (
    <CompareScenariosContext.Provider
      value={{
        scenariosToCompare,
        setScenariosToCompare,
      }}
    >
      {children}
    </CompareScenariosContext.Provider>
  );
}
