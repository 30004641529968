import React, { useState } from "react";
import { projectData } from "../modules/Projects/components/ProjectList/mockData";

export const CreateProjectContext = React.createContext(null);

export function CreateProjectProvider({ children }) {
  const [projectList, setProjectList] = useState(projectData);
  const [newProject, setNewProject] = useState({});
  return (
    <CreateProjectContext.Provider
      value={{
        projectList,
        setProjectList,
        newProject,
        setNewProject,
      }}
    >
      {children}
    </CreateProjectContext.Provider>
  );
}
