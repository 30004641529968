import { Navigation } from "@dentsu-ui/components";
import { NavLink } from "react-router-dom";
import cmsListing from "../../cms/Listing";

const SideNavigation = () => (
  <Navigation as={NavLink}>
    <Navigation.Section
      title={cmsListing.BPL_sidenavigation_title_businessPlanner}
      items={[
        {
          label: "Projects",
          url: "/",
          isDisabled: false,
          exact: true,
        },
      ]}
    />
  </Navigation>
);

export default SideNavigation;
