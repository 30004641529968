import { useCMS } from "@gdo-global-client-reporting/dentsu-platform-shell";
import { createBridge } from "@gdo-global-client-reporting/dentsu-platform-bridge";
import config from "./config";
import App from "./app";

const BridgeIntegration = () => {
  // const userId = getUserId();
  // const userType = getUserType();
  const { changeLanguage } = useCMS();
  const { appOrigin, isBypassSecurity } = config;

  // useEffect(() => {
  createBridge({
    localeChangeHandler: (locale) => {
      console.log("locale==", locale);
      changeLanguage(locale);
    },
    parentOrigin: appOrigin && appOrigin.trim(),
    isBypassSecurity,
  });
  // }, [changeLanguage, appOrigin, isBypassSecurity]);

  // const gtmParams = {
  //   id: gtmId,
  //   environment: {
  //     gtm_auth: gtmAuth,
  //     gtm_preview: gtmPreview,
  //   },
  //   dataLayer: {
  //     event: "init",
  //     user_id: userId,
  //     user_type: !userType ? "dentsu" : userType,
  //     essential: true,
  //     performance: true,
  //   },
  // };

  return (
    // <TagManager params={gtmParams}>
    <App />
    // </TagManager>
  );
};

export default BridgeIntegration;
